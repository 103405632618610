
const URLS = {
    DEV: 'https://localhost:44356/api/Employees',
    PROD: 'https://api.cloudflare.com/client/v4/zones/v1.0-2fe899c8f9432ce8cab9bd52-0d1b2429d8e2b46325d41e6ce77d3a33fde7359fe119d5c0a303b19254abdaf2816f20d1ee2adbc3b52dd9131911f7dc7f216c526ff66bcb9935b0c79d16ed204db01218ca50d402ac'
}

// const cors = require("cors")
// URLS.use(cors({
//     origin:"*"
// })
// )

const URL = URLS.PROD;

export default URL;