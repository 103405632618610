import React, {useState}from 'react';
import Axios from 'axios';
import Swal from 'sweetalert2';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
// import Email from '@material-ui/icons/MailOutline';
import Email from '@mui/icons-material/ContactMailOutlined';
import URL from '../utils/SYSTEM';



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.dark,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },

    sendEmail: {
        margin: theme.spacing(3, 0, 2),
        color: theme.palette.text.primary,
    },
}));

export default function Form() {
    const classes = useStyles();

      
    const [data, setData ] = useState({
        FirstName: "",
        LastName: "",
        Gender:"",
        Salary: "",
       
    } )
    function sendEmail(e){
        e.preventDefault()
       
        Axios.post(URL,{
            FirstName: data.FirstName,
            LastName: data.LastName,
            Gender: data.Gender,
            Salary: data.Salary,
            
        }
        )

        .then((result) => {
            console.log(result.text);
            Swal.fire({
                icon: 'success',
                title: 'Message Sent Successfully'
            })
        },
            (error) => {
                console.log(error.text);
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops, please enter the correct details',
                    text: error.text,
                })
            })
            

           

    }
    function handle(e){
        const newdata={...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)

        
        console.log(newdata)
        

        

    }

   

    return (

        <Container maxWidth="md">          
            <div className={classes.paper} >
                
                <br />
                <Avatar className={classes.avatar}>
                    <Email />
                </Avatar>
                <br />
                <Typography component="h1" variant="h5">
                    Customer Form
                </Typography>
                <form className={classes.form} validate="true" autoComplete="true" onSubmit={sendEmail} >
                    <Grid container spacing={3} >
                        
                        <Grid item xs={12} sm={6}>
                            <TextField
                            onChange={(e)=>handle(e)}
                                autoComplete="FirstName"
                                name="FirstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="FirstName"
                                label="First Name"
                                type="text"
                                value={data.FirstName}
                                 
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                            onChange={(e)=>handle(e)}
                                variant="outlined"
                                required
                                fullWidth
                                id="LastName"
                                label="Last Name"
                                name="LastName"
                                autoComplete="LastName"
                                type="text"
                                value={data.LastName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            onChange={(e)=>handle(e)}
                                variant="outlined"
                                required
                                fullWidth
                                name="Gender"
                                label="Gender"
                                id="Gender"
                                type="text"
                                value={data.Gender}

                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                            onChange={(e)=>handle(e)}
                                variant="outlined"
                                required
                                fullWidth
                                id="Salary"
                                label="Salary"
                                name="Salary"
                                autoComplete="Salary"
                                type="text"
                                value={data.Salary}
                            />
                        </Grid>

                      
                       
                    </Grid>
                    <br />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"

                        className={classes.submit}
                    >
                        Send
                    </Button>
                    

                </form>



            </div>
        </Container>

    );
}