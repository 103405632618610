
import './App.css';
import React from 'react';
// import PostForm from "./components/PostForm";
// import Form from './components/Form';
import Forms from './components/Forms';
import DataGrid from './Table/DataGrid';


function App() {
 

  return (
    <div className="App">
      
      
      <Forms />
      <DataGrid/>

    </div>
  );
}

export default App;
