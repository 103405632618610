import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import URL from '../utils/SYSTEM';




function ListData (){

  const getlist = () => {
    // axios.get("https://localhost:44356/api/Employees")
    axios.get(URL)
    .then(res => {
      console.log(res)

    }).catch(err =>{
      console.log(err)
    })
  }
  return(
    <div>
      <Button onClick={getlist}>Display List</Button>
    </div>
  );

}
export default ListData